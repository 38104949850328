import { AppService } from './AbstractService';

export default class FortuneWheelService extends AppService {
  public spin() {
    return this.makeRequest('POST', '/transactions/bet-fortune-wheel');
  }

  public fetchData() {
    return this.makeRequest('GET', '/transactions/fortune-wheel');
  }

  public invalidFortunWheel(id: string, data: any) {
    return this.makeRequest('PUT', `/transactions/fortune-wheel/${id}`, data);
  }
}
