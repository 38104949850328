





































































import {
  Component, Vue, Prop, Emit,
} from 'vue-property-decorator';
import FortuneWheel from 'vue-fortune-wheel';
import 'vue-fortune-wheel/lib/vue-fortune-wheel.css';
// eslint-disable-next-line import/no-cycle
import FortuneWheelService from '@/services/FortuneWheelService';
import Profile from '@/connector/Profile.vue';

const fortuneWheelService = new FortuneWheelService();

@Component({
  components: {
    FortuneWheel,
    Profile,
  },
})
export default class FortuneWheelPage extends Vue {
  prize = 0;

  disabled = false;

  retry = false;

  fortuneWheelData: any = {};

  // eslint-disable-next-line class-methods-use-this
  async spin(rotate) {
    try {
      this.disabled = true;
      const { data } = await fortuneWheelService.spin();
      // eslint-disable-next-line max-len
      this.prize = this.prizes.findIndex((prize) => Number(prize.value) === Number(data.prizeId)) + 1;
      console.log('this.prize', this.prize);
      rotate();
      this.fortuneWheelData = { ...data.fortuneWheelData };
    } catch (e) {
      this.disabled = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async onSpinStop() {
    this.$store.commit('Snackbar/showNotification', { message: `ยินดีด้วยคุณได้รับรางวัล ${this.prizes[this.prize - 1].value} บาท`, error: false });
    this.$store.dispatch('Profile/fetchBalance');
    this.$store.dispatch('Profile/getProfile');
    this.disabled = false;
  }

  // eslint-disable-next-line class-methods-use-this
  get prizes() {
    return [
      {
        id: 1,
        name: '1000',
        value: 1000,
        bgColor: '#45ace9',
        color: '#ffffff',
        probability: 1,
      },
      {
        id: 2,
        name: '5',
        value: 5,
        bgColor: '#dd3832',
        color: '#ffffff',
        probability: 45,
      },
      {
        id: 3,
        name: '50',
        value: 50,
        bgColor: '#fef151',
        color: '#ffffff',
        probability: 13,
      },
      {
        id: 4,
        name: '500',
        value: 500,
        bgColor: '#45ace9',
        color: '#ffffff',
        probability: 1,
      },
      {
        id: 5,
        name: '10',
        value: 10,
        bgColor: '#fef151',
        color: '#ffffff',
        probability: 35,
      },
      {
        id: 6,
        name: '100',
        value: 100,
        bgColor: '#dd3832',
        color: '#ffffff',
        probability: 5,
      },
    ];
  }

  async fetchFortuneWheelData() {
    try {
      const { data } = await fortuneWheelService.fetchData();
      this.fortuneWheelData = { ...data };
      this.retry = false;
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.retry = true;
    }
  }

  async created() {
    await this.fetchFortuneWheelData();
  }

  async destroyed() {
    // eslint-disable-next-line no-underscore-dangle
    if (this.fortuneWheelData._id) {
      // eslint-disable-next-line no-underscore-dangle
      await fortuneWheelService.invalidFortunWheel(this.fortuneWheelData._id, { eligible: 2 });
    }
  }
}
